import CarrierLogoSameSize from '@components/molecules/CarrierLogoSameSize';
import BREAKPOINTS from '@lib/constants/breakpoints';
import COLORS from '@lib/constants/colors';
import { carrierDictionary } from '@lib/helpers/texts';
import { useAmp } from 'next/amp';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ duration: number }>`
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
  padding: 16px 0;
  @keyframes logo-list-infinite-scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .logos-container {
    flex: 0 0 auto;
    min-width: 100%;
    z-index: 1;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    animation: logo-list-infinite-scroll ${(props) => props.duration}s linear 0s
      infinite;
    animation-play-state: running;
    animation-delay: 0s;
    animation-direction: normal;

    img {
      filter: saturate(0);
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    .logos-container {
    }
  }
`;

const CarriersLogoList = ({
  pastel = false,
  altPrefix = 'Seguro auto da ',
  speed = 20,
  priority = false,
}: {
  pastel?: boolean;
  altPrefix?: string;
  speed?: number;
  priority?: boolean;
}) => {
  const sorted = Object.values(carrierDictionary).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const isAmp = useAmp();

  const values = [...sorted, ...sorted];

  const containerRef = useRef<HTMLDivElement>(null);
  const marqueeRef = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [duration, setDuration] = useState(0);

  const calculateWidth = () => {
    // Find width of container and width of marquee
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
    }

    if (marqueeWidth < containerWidth) {
      setDuration(containerWidth / speed);
    } else {
      setDuration(marqueeWidth / speed);
    }
  };

  useEffect(() => {
    calculateWidth();
    // Rerender on window resize
    window.addEventListener('resize', calculateWidth);
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  });

  if (isAmp) return null;

  return (
    <Wrapper
      ref={containerRef}
      duration={duration}
      style={{ backgroundColor: pastel ? COLORS.pastel : '#FFF' }}
    >
      <div ref={marqueeRef} className="logos-container">
        {values.map((c, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ margin: '0 40px' }}
          >
            <CarrierLogoSameSize
              logoUrl={c.logoUrl}
              name={`${altPrefix} ${c.name}`}
              height={40}
              width={120}
            />
          </div>
        ))}
      </div>
      <div className="logos-container">
        {values.map((c, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ margin: '0 40px' }}
          >
            <CarrierLogoSameSize
              logoUrl={c.logoUrl}
              name={c.name}
              height={40}
              width={120}
              priority={priority}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default CarriersLogoList;
