import GridBox from '@components/atoms/GridBox';
import StarIcon from '@components/atoms/icons/StarIcon';
import CarriersLogoList from '@components/organisms/CarriersLogoList';
import { useGetQuoteFromLocalStorage } from '@lib/hooks/localStorage';
import PrimaryButton from 'components/atoms/buttons/PrimaryButton';
import Section from 'components/atoms/Section';
import { Heading1, LightParagraph } from 'components/atoms/Typography';
import BREAKPOINTS from 'lib/constants/breakpoints';
import COLORS from 'lib/constants/colors';
import ROUTES from 'lib/constants/routes';
import { trackGetQuoteClick } from 'lib/services/analytics';
import { useAmp } from 'next/amp';
import NextImage from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from 'types/frontEndTypes';

const HomeTextWrapper = styled.div`
  max-width: 462px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  button {
    min-width: 320px;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    max-width: none;

    text-align: center;

    button {
      width: 100%;
      min-width: unset;
    }
  }

  h1 {
    margin-bottom: 8px;
  }

  strong {
    color: ${COLORS.black};
    font-size: 19px;
  }
`;

const Header = styled.header`
  position: relative;
  padding-top: 80px;
  height: 100vh;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 61px;
    height: auto;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  min-height: 400px;
  height: 100%;
  max-height: 800px;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`;

const SectionWrapper = styled(Section)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  padding: 0;
  height: calc(100vh - 80px - 82px);

  @media screen and (max-width: 1100px) {
    padding-left: 32px;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: 1fr;
    padding: 32px;
    height: auto;
  }
`;

const BackToQuoteLink = styled.a`
  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    display: none;
  }

  display: flex;
  color: ${COLORS.pink};
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  padding: 9px 18px 12px;
  box-shadow: rgb(0 0 0 / 16%) 0px 47px 46px -27px, rgb(0 0 0 / 6%) 0px 2px 12px;
  border-radius: 100px;
  margin-bottom: 19px;
  width: 215px;
  height: 38px;
  -webkit-tap-highlight-color: transparent;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;

  justify-content: space-between;
`;

const HomeText: FC<{
  title: string;
  subtitle: string;
  buttonText: string;
  underButton?: React.ReactNode;
}> = ({ title, subtitle, buttonText, underButton }) => {
  const quote = useGetQuoteFromLocalStorage();

  return (
    <HomeTextWrapper>
      {quote && (
        <Link href={ROUTES.offers(quote.id)}>
          <BackToQuoteLink>
            <span>Voltar para cotação</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0378 6.34317L13.6269 7.76069L16.8972 11.0157L3.29211 11.0293L3.29413 13.0293L16.8619 13.0157L13.6467 16.2459L15.0643 17.6568L20.7079 11.9868L15.0378 6.34317Z"
                fill="currentColor"
              />
            </svg>
          </BackToQuoteLink>
        </Link>
      )}
      <Heading1>{title}</Heading1>
      <LightParagraph>{subtitle}</LightParagraph>
      <Link href={ROUTES.startForm()}>
        <a suppressHydrationWarning>
          <PrimaryButton
            boxShadow
            pink
            style={{ margin: '32px 0px 0px' }}
            onClick={() => trackGetQuoteClick('home header button')}
            suppressHydrationWarning
          >
            {buttonText}
          </PrimaryButton>
        </a>
      </Link>
      {underButton}
    </HomeTextWrapper>
  );
};

const RatingWrapper = styled.div`
  div:first-child {
    display: flex;
    align-items: center;
    margin-top: 48px;

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      justify-content: center;
    }
  }

  strong {
    font-weight: 700;
    line-height: 1.5;
  }

  span svg {
    height: 20px;
    margin: 0 5px 0;
    width: 20px;
  }

  p {
    margin-top: 8px;
    font-size: 0.875rem;
  }
`;

const Rating = () => (
  <RatingWrapper>
    <div>
      <strong>5.0</strong>
      <span>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </span>
    </div>
    <GridBox
      alignItems="baseline"
      justifyContent={['center', 'flex-start', 'flex-start']}
    >
      <p>Avaliações no</p>
      <svg
        style={{ marginLeft: 16, transform: 'scale(1.5)' }}
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="14"
        fill="none"
        viewBox="0 0 33 14"
      >
        <clipPath id="a">
          <path d="M.5 0h31.634v14H.5z" />
        </clipPath>
        <g clipPath="url(#a)">
          <path
            fill="#fbbb00"
            d="M3.492 8.441l-.427 1.82H1.248C.821 9.3.5 8.231.5 6.948c0-1.175.32-2.244.748-3.206l1.603.32.633 1.485c-.107.427-.205.974-.205 1.401 0 .535.037 1.017.213 1.493z"
          />
          <path
            fill="#518ef8"
            d="M14.063 5.781c.107.428.107.855.107 1.283 0 .534 0 .962-.107 1.496-.32 1.603-1.093 2.978-2.375 3.94l-2.114-.093-.32-1.71c.855-.427 1.496-1.175 1.816-2.137H7.33V5.78h3.74z"
          />
          <path
            fill="#28b446"
            d="M11.828 12.396c-1.175.961-2.672 1.496-4.381 1.496-2.672 0-5.023-1.496-6.199-3.634l2.244-1.817c.642 1.604 2.138 2.672 3.848 2.672.748 0 1.496-.214 2.137-.534z"
          />
          <path
            fill="#f14336"
            d="M11.935 1.603L9.691 3.42c-.641-.32-1.39-.641-2.244-.641-1.817 0-3.42 1.175-3.955 2.778L1.248 3.74A6.988 6.988 0 017.447 0c1.71 0 3.312.641 4.488 1.603z"
          />
          <path
            fill="#1877f2"
            d="M32.133 6.947C32.133 3.099 28.927 0 25.079 0a6.933 6.933 0 00-6.946 6.947c0 3.526 2.565 6.412 5.878 6.946V8.977h-1.817v-2.03h1.817V5.45c0-1.71 1.068-2.671 2.671-2.671.749 0 1.604.107 1.604.107v1.71h-.855c-.855 0-1.176.534-1.176 1.068v1.283h1.924l-.321 2.03h-1.603v4.916c3.313-.534 5.878-3.42 5.878-6.946z"
          />
          <path
            fill="#fff"
            d="M27.86 8.976l.32-2.03h-1.918V5.662c0-.535.32-1.069 1.176-1.069h.862v-1.71s-.868-.107-1.723-.107c-1.496 0-2.565.962-2.565 2.672v1.496h-1.817v2.03h1.817v4.917c.34.048.748.107 1.069.107s.816-.024 1.175-.107V8.976z"
          />
        </g>
      </svg>
    </GridBox>
  </RatingWrapper>
);

const HomeHeader: FC<{
  title: string;
  subtitle: string;
  buttonText: string;
  image: Image;
  underButton?: React.ReactNode;
}> = ({ subtitle, title, image, buttonText, underButton = <Rating /> }) => {
  const isAmp = useAmp();

  return (
    <Header>
      <SectionWrapper>
        <HomeText
          buttonText={buttonText}
          title={title}
          subtitle={subtitle}
          underButton={underButton}
        />
        {!isAmp && (
          <ImageContainer>
            {image.url && (
              <NextImage
                alt={image.alt}
                src={image.url}
                layout="fill"
                quality={100}
                objectFit="cover"
              />
            )}
          </ImageContainer>
        )}
      </SectionWrapper>
      {!isAmp && <CarriersLogoList pastel />}
    </Header>
  );
};

export default HomeHeader;
