import AmpWrap from '@components/atoms/amp/AmpWrap';
import PrimaryButton from '@components/atoms/buttons/PrimaryButton';
import GridBox from '@components/atoms/GridBox';
import Spacing from '@components/atoms/Spacing';
import COLORS from '@lib/constants/colors';
import ROUTES from '@lib/constants/routes';
import Section from 'components/atoms/Section';
import { Heading2, Heading4, Paragraph } from 'components/atoms/Typography';
import BREAKPOINTS from 'lib/constants/breakpoints';
import NextImage from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { HowStep, Image } from 'types/frontEndTypes';

const Wrapper = styled(Section)`
  p {
    font-size: 18px;

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      font-size: 16px;
    }
  }

  .image-wrapper {
    width: 100%;
    position: relative;
    min-height: 450px;

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      height: 250px;
      min-height: auto;
    }
  }

  ul {
    margin-top: ${8 * 12}px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      grid-template-columns: 1fr;
      margin-top: 48px;

      li {
        /* display: flex; */
        margin-top: 16px;
        text-align: center;

        .icon-wrp {
          min-width: 40px;
        }

        h4 {
          font-size: 20px;
          margin-bottom: 12px;
        }
      }
    }

    li {
      p {
        font-size: 16px;
      }

      h4 {
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
    }

    text-align: center;
  }
`;

const HowItWorks = ({
  title,
  subtitle,
  steps,
  image,
  button = {
    text: 'Simular Grátis',
    href: ROUTES.startForm(),
  },
}: {
  title: string;
  subtitle: string;
  steps: HowStep[];
  image: Image;
  button?: { text: string; href: string };
}) => (
  <Wrapper id="como-funciona">
    <Grid>
      <AmpWrap
        nonAmp={
          <div className="image-wrapper">
            {image.url && (
              <NextImage
                layout="fill"
                src={image.url}
                alt={image.alt}
                quality={100}
                objectFit="contain"
              />
            )}
          </div>
        }
      />
      <GridBox
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Heading2>{title}</Heading2>

        <Spacing top={3} bottom={4}>
          <Paragraph>{subtitle}</Paragraph>
        </Spacing>

        <Link href={button.href}>
          <PrimaryButton>{button.text}</PrimaryButton>
        </Link>
      </GridBox>
    </Grid>

    <ul>
      {steps.map((step) => (
        <li key={step.title}>
          {step?.icon?.url && (
            <AmpWrap
              nonAmp={
                <Spacing className="icon-wrp" bottom={1}>
                  <NextImage
                    src={step.icon.url}
                    alt={step.icon.alt}
                    height={8 * 12}
                    width={8 * 12}
                  />
                </Spacing>
              }
            />
          )}
          <div>
            <Heading4>{step.title}</Heading4>
            <Paragraph style={{ color: COLORS.gray }}>
              {step.description}
            </Paragraph>
          </div>
        </li>
      ))}
    </ul>
  </Wrapper>
);

export default HowItWorks;
