import AmpWrap from '@components/atoms/amp/AmpWrap';
import { Maybe } from '@graphql/generated';
import ROUTES from '@lib/constants/routes';
import { carrierDictionary } from '@lib/helpers/texts';
import Section from 'components/atoms/Section';
import { Heading2, Heading4, Paragraph } from 'components/atoms/Typography';
import BREAKPOINTS from 'lib/constants/breakpoints';
import NextImage from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import styled from 'styled-components';

const TextsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 32px;
  max-width: 500px;

  button {
    min-width: 320px;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    text-align: center;
    margin-right: 0;
    max-width: none;

    button {
      margin: 0 auto;
      width: 100%;
      min-width: unset;
    }
  }
`;

const StyledSection = styled(Section)`
  ul {
    margin-top: 64px;

    display: flex;
    justify-content: space-around;

    li {
      text-align: center;

      margin: 0 16px;

      max-width: 300px;

      h4 {
        margin-bottom: 8px;
        display: block;
      }
    }

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      flex-wrap: wrap;


      li {

        margin: 16px 0;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  flex-wrap: wrap;

  .img-container {
    margin: 32px;
    height: 66px;
    width: 100px;
    position: relative;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    .img-container {
      margin: 8px;
    }
  }
`;

const Grid = styled.div`
  overflow: hidden;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: 1fr;
  }
`;

const Products: FC<{
  title?: Maybe<string>;
}> = ({ title }) => {
  const sortedCarriers = Object.entries(carrierDictionary).sort((a, b) =>
    a[1].name.localeCompare(b[1].name),
  );


  return (
    <StyledSection>
      <Grid>
        <TextsWrapper>
          <Heading2>{title}</Heading2>
        </TextsWrapper>
        <AmpWrap
          nonAmp={
            <ImageWrapper>
              {sortedCarriers.map(([id, c]) => (
                <div className="img-container" key={c.logoUrl}>
                  <Link passHref href={ROUTES.carriers.carrier(id)}>
                    <a>
                      <NextImage
                        src={c.logoUrl}
                        alt={`Logo seguradora ${c.name} para seguro auto`}
                        layout="fill"
                        objectFit="contain"
                      />
                    </a>
                  </Link>
                </div>
              ))}
            </ImageWrapper>
          }
        />
      </Grid>

      <ul>
        {[
          {
            text: 'Faça uma simulação',
            description:
              'Preencha o formulário com seus dados para ver ofertas personalizadas.',
          },
          {
            text: 'Compare as ofertas',
            description:
              'Compare preço e cobertura das seguradoras de carros e escolha o seguro que precisa..',
          },
          {
            text: 'Agende a vistoria',
            description:
              'Você pode fazer a vistoria na sua casa, na oficina ou a autovistoria online.',
          },
          {
            text: 'Ande tranquilo',
            description:
              'Após aprovação da vistoria, você recebe a apólice por e-mail e já está seguro!',
          },
        ].map(({ text, description }) => (
          <li key={text}>
            <Heading4>{text}</Heading4>
            <Paragraph>{description}</Paragraph>
          </li>
        ))}
      </ul>
    </StyledSection>
  );
};

export default Products;
