import AmpWrap from '@components/atoms/amp/AmpWrap';
import Image from 'next/image';

export default function CarrierLogoSameSize({
  logoUrl,
  name,
  height = 40,
  width = 120,
  priority = false,
}: {
  logoUrl: string;
  name: string;
  height?: number;
  width?: number;
  priority?: boolean;
}) {
  return (
    <AmpWrap
      nonAmp={
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            height,
            width,
          }}
        >
          <Image
            src={logoUrl}
            alt={name}
            layout="fill"
            objectFit="contain"
            priority={priority}
          />
        </div>
      }
      ampOnly={
        <amp-img src={logoUrl} alt={name} height={height} width={width} />
      }
    />
  );
}
