import React from 'react';

function StarIcon({
  stroke ='#FEA530',
  bgColor = '#FEA530',
}: {
  stroke?: string;
  bgColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
    >
      <linearGradient
        id="rating-fill-66de87e6-44be-40a0-9c14-160bb09ebbdb"
        x1="0"
        x2="1"
        y1="0"
        y2="0"
      >
        <stop className="rating-fill" offset="100%" stopColor={bgColor} />
        <stop offset="0" stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#rating-fill-66de87e6-44be-40a0-9c14-160bb09ebbdb)"
        fillRule="evenodd"
        stroke={stroke}
        strokeMiterlimit="10"
        d="M15.21 5.59l-4.84-.66L8.28.69a.31.31 0 00-.57 0L5.53 4.93l-4.75.66a.33.33 0 00-.19.56l3.52 3.3-.86 4.72c-.06.38.21.37.38.28l4.27-2.17 4.27 2.17a.37.37 0 00.48-.38l-.86-4.71 3.61-3.21a.32.32 0 00-.19-.56z"
      />
    </svg>
  );
}

export default StarIcon;
