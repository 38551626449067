import { FipeVehicleTypeUrl } from '@lib/constants/routes/fipeRoutes';
import { FipeVehicleType } from '@prisma/client';
import { AssistanceType, CarrierId, CarrierInfo, ContactType, Coverage, CoverageType, DebtsType, MaritalStatus, OfferAssistance, PaymentMethod, VehicleType } from '@graphql/generated';
import { Assistance, OfferCoverage } from 'repositories';
import { integerToCurrency } from './misc';
import { staticImageFile } from './publicFiles';

export const maritalStatusDic: Record<MaritalStatus, string> = {
  divorced: 'Divorciado(a) ou Separado(a)',
  married: 'Casado(a) ou união estável',
  single: 'Solteiro(a)',
  widower: 'Viúvo(a)',
};

export function getAssistanceInfo(assist: Assistance): OfferAssistance {
  const dic: Record<
    AssistanceType,
    Omit<Omit<OfferAssistance, 'type'>, 'label'> & {
      label: (value: number) => string;
    }
  > = {
    guincho: {
      name: 'Guincho',
      label: (value: number) => (value > 9999 ? 'KM ilimitado' : `${value}KM`),
      description:
        'Quando precisar, solicite um guincho para buscar seu veículo em qualquer lugar do Brasil.',
    },
    taxi: {
      name: 'Transporte domiciliar',
      label: () => `Incluso`,
      description:
        'Em caso de roubo ou furto do veículo, a Central de Assistência da Seguradora providenciará o transporte do motorista e de seus ocupantes até o local de escolha do cliente.',
    },
    borracheiro: {
      name: 'Borracheiro',
      label: () => `Incluso`,
      description:
        'Se tiver problemas com o pneu, seu veículo será rebocada até o borracheiro mais próximo.',
    },
    carroReserva: {
      name: 'Carro reserva',
      label: (value: number) => `${value} dias`,
      description:
        'Se você ficar sem veículo porque precisou acionar alguma cobertura prevista no contrato do seu seguro (alagamento, colisão, incêndio, roubo ou furto), essa assistência dá direito a um veículo reserva por alguns dias',
    },
    chaveiro: {
      name: 'Chaveiro',
      label: () => `Incluso`,
      description:
        'Se tiver problema com a chave do seu veículo, a seguradora mandará alguém pra resolver pra você ou pra fazer uma nova chave, se for o caso.',
    },
    vidros: {
      name: 'Vidros, faróis, lanternas e retrovisores',
      label: () => `Incluso`,
      description:
        'Cobertura para Vidros, faróis, lanternas e retrovisores com uma franquia menor',
    },
  };

  const result = dic[assist.type];

  return { ...result, type: assist.type, label: result.label(assist.value) };
}

export const carrierDictionary: Record<CarrierId, Omit<CarrierInfo, 'id'>> = {
  ituran: {
    name: 'Ituran',
    reclameAquiRate: 7.4,
    logoUrl: staticImageFile('logos/ituran.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Telefone',
            value: '0800 015 3600',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          { label: 'São Paulo', value: '11 3616 9000', type: ContactType.Tel },
          {
            label: 'Demais localidades',
            value: '0800 774 2000',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  youse: {
    name: 'Youse',
    reclameAquiRate: 7.9,
    logoUrl: staticImageFile('logos/youse.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Capitais e regiões metropolitanas',
            value: '3003 5770',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0800 730 9901',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  suhai: {
    name: 'Suhai',
    reclameAquiRate: 8.9,
    logoUrl: staticImageFile('logos/suhai.png'),
    contacts: [
      {
        title: 'Sinistro',
        info: [
          {
            label: 'Telefone',
            value: '3003-0335',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'Assistência 24h',
        info: [
          {
            label: 'Telefone',
            value: '0800-327-8424',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '3003-0335',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'Ouvidoria',
        info: [
          {
            label: 'Telefone',
            value: '0800 772 12 14',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  allianz: {
    name: 'Allianz',
    reclameAquiRate: 7.3,
    logoUrl: staticImageFile('logos/allianz.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Telefone',
            value: '0800 0130 700',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '08000 115 215',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  porto: {
    name: 'Porto Seguro',
    reclameAquiRate: 7.5,
    logoUrl: staticImageFile('logos/porto.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Grande SP',
            value: '333 76786',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0300 337 6786',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 727 2766',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  azul: {
    name: 'Azul Seguros',
    reclameAquiRate: 8.0,
    logoUrl: staticImageFile('logos/azul.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Capitais e regiões metropolitanas',
            value: '4004 3700',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0300 123 2985',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 703 1280',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  bradesco: {
    name: 'Bradesco Seguros',
    reclameAquiRate: 7.4,
    logoUrl: staticImageFile('logos/bradesco.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Capitais e regiões metropolitanas',
            value: '4004 2757',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0800 701 2757',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 727 9966',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  hdi: {
    name: 'HDI',
    reclameAquiRate: 7.4,
    logoUrl: staticImageFile('logos/hdi.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Capitais e regiões metropolitanas',
            value: '3003-5390',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0800 434 4340',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 722 71 49',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  tokio: {
    name: 'Tokio Marine',
    reclameAquiRate: 8.0,
    logoUrl: staticImageFile('logos/tokio.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Telefone',
            value: '0300 33 86546',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 703 9000',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  mapfre: {
    name: 'Mapfre',
    reclameAquiRate: 7.2,
    logoUrl: staticImageFile('logos/mapfre.png'),
    contacts: [
      {
        title: 'Emergência',
        info: [
          {
            label: 'Capitais e regiões metropolitanas',
            value: '4004 0101',
            type: ContactType.Tel,
          },
          {
            label: 'Demais localidades',
            value: '0800 705 0101',
            type: ContactType.Tel,
          },
        ],
      },
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 775 4545',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  justos: {
    name: 'Justos',
    reclameAquiRate: 8.6,
    logoUrl: staticImageFile('logos/justos.png'),
    contacts: [
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '3003-0926',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  zurich: {
    name: 'Zurich',
    reclameAquiRate: 6.9,
    logoUrl: staticImageFile('logos/zurich.png'),
    contacts: [
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 285 4141',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
  sompo: {
    name: 'Sompo',
    reclameAquiRate: 8.1,
    logoUrl: staticImageFile('logos/sompo.png'),
    contacts: [
      {
        title: 'SAC',
        info: [
          {
            label: 'Telefone',
            value: '0800 77 19 719',
            type: ContactType.Tel,
          },
        ],
      },
    ],
  },
};

export function getCarrierInfo(carrier: CarrierId) {
  return { ...carrierDictionary[carrier], id: carrier };
}

export function getCoverageInfo(coverage: OfferCoverage): Coverage {
  const dic: Record<
    CoverageType,
    { name: string; label: (value: number) => string; description: string }
  > = {
    fire: {
      name: 'Incêndio',
      description:
        'Se seu veículo der PT porque pegou fogo, explodiu ou caiu um raio, você recebe a indenização integral pra compensar o prejuízo.',
      label: (value) => `${value}% FIPE`,
    },
    theft: {
      name: 'Roubo e Furto',
      description:
        'Seu veículo foi roubado? Calma, calma. Se ele não for encontrado a você receberá o valor total do seu bem. Mas se for encontrado e estiver bem, bem detonado – deu PT – o seguro irá te reembolsar.',
      label: (value) => `${value}% FIPE`,
    },
    totalLoss: {
      name: 'Perda Total',
      description:
        'Aconteceu um acidente e não vai ser possível consertar os danos do seu veículo? Pode ficar numa boa. Se rolar perda total com batidas, capotagens, queda do veículo ou queda de objetos, você será te indenizado. O preço do conserto precisa ser maior que 75% do valor do veículo de acordo com a tabela FIPE no período do acionamento.',
      label: (value) => `${value}% FIPE`,
    },
    thirdPartyMaterial: {
      name: 'Danos materiais a terceiros',
      description:
        'Se acontecer um acidente que envolva os bens de outras pessoas, você conta com uma indenização pra bancar as despesas que tiver com reparos, reposições e até mesmo com advogados, se for o caso.',
      label: (value) => integerToCurrency(value),
    },
    thirdPartyBody: {
      name: 'Danos corporais a terceiros',
      label: (value) => integerToCurrency(value),
      description:
        'Se você se envolver em algum acidente com pessoas feridas, a seguradora te te ajudará nas despesas que for obrigado a pagar. Ah, você também pode usar essa cobertura pra pagar os advogados, se alguém te processar.',
    },
    moralDamages: {
      name: 'Danos morais',
      description:
        'Caso você seja processado por alguém que tenha insultado durante o uso do veículo essa cobertura irá te ajudar com os gastos com advogados e com as despesas que for obrigado a pagar.',
      label: (value) => integerToCurrency(value),
    },
    appDeath: {
      name: 'Acidentes de passageiros - Morte',
      description:
        'Aqui você garante a indenização por morte de qualquer passageiro que sofrer essa fatalidade no seu veículo.',
      label: (value) => integerToCurrency(value),
    },

    appInvalid: {
      name: 'Acidentes de passageiros - Invalidez',
      description:
        'Aqui você garante a indenização por invalidez permanente, parcial ou total a passageiros que sofrerem acidentes no seu veículo.',
      label: (value) => integerToCurrency(value),
    },
    comprehensive: {
      name: 'Compreensiva',
      description:
        'Essa cobertura te indeniza se rolar qualquer tipo batida, capotagem, incêndio ou alagamento com o seu veículo. Se ele for roubado ou furtado e, depois, encontrado com problemas, você também pode usar essa cobertura.',
      label: () => 'Incluso',
    },
    colision: {
      name: 'Perda Parcial',
      description:
        'Essa cobertura te indeniza se rolar qualquer tipo batida, capotagem, incêndio ou alagamento com o seu veículo. Se ele for roubado ou furtado e, depois, encontrado com problemas, você também pode usar essa cobertura.',
      label: () => 'Incluso',
    },
    nature: {
      name: 'Alagamento e eventos da natureza',
      description:
        'Se seu veículo foi pego de surpresa no meio de uma inundação, alagamento, granizo ou ventos fortes e deu PT, não se preocupe. Você tem direito ao valor integral pra cobrir seu prejuízo.',
      label: (value) => `${value}% FIPE`,
    },
  };
  const result = dic[coverage.type];

  return { ...result, ...coverage, label: result.label(coverage.value) };
}

export type InsurancePageId =
  | 'seguro-carro'
  | 'seguro-moto'
  | 'seguro-caminhao';

const insurancePageDictionary: Record<
  InsurancePageId,
  {
    type: VehicleType.Car | VehicleType.Motorcycle  | VehicleType.Trucks;
    name: string;
    productName: string;
  }
> = {
  'seguro-carro': {
    type: VehicleType.Car,
    name: 'Carro',
    productName: 'Seguro Auto',
  },
  'seguro-moto': {
    type: VehicleType.Motorcycle,
    name: 'Moto',
    productName: 'Seguro Moto',
  },
  "seguro-caminhao": {
    type: VehicleType.Trucks,
    name: "Caminhão",
    productName: "Seguro Caminhão",
  },
};

export function getInsurancePageInfo(id?: InsurancePageId) {
  if (!id) return null;

  return insurancePageDictionary[id];
}

export const fipeDictionary: Record<
  FipeVehicleTypeUrl,
  {
    plural: string;
    singular: string;
    fipeType: FipeVehicleType;
    insurancePage: InsurancePageId;
  }
> = {
  caminhoes: {
    plural: 'Caminhões',
    singular: 'Caminhão',
    fipeType: 'trucks',
    insurancePage: 'seguro-caminhao',
  },
  carros: {
    plural: 'Carros',
    singular: 'Carro',
    fipeType: 'car',
    insurancePage: 'seguro-carro',
  },
  motos: {
    plural: 'Motos',
    singular: 'Moto',
    fipeType: 'motorcycle',
    insurancePage: 'seguro-moto',
  },
};

export function getPaymentName(method: PaymentMethod) {
  const dic: Record<PaymentMethod, string> = {
    boleto: 'Boleto',
    creditCard: 'Cartão de crédito',
    debit: 'Débito em conta',
  };

  return dic[method];
}

export const debtsDictionary: Record<DebtsType, string> = {
  ticket: 'Multa de trânsito',
  renainf_ticket: 'Multa gerenciadas pelo RENAINF',
  licensing: 'Licenciamento do veículo',
  expired_insurance: 'Seguro Obrigatório do veículo (DPVAT)',
  insurance: 'Seguro Obrigatório do veículo (DPVAT)',
  ipva: 'IPVA',
  ipva_unique: 'IPVA',
  ipva_installment: 'Parcela do IPVA',
  services: 'Taxas de serviços diversos, como multa de pátio.',
  service: 'Taxas de serviços diversos, como multa de pátio.',
  dpvat: 'Seguro Obrigatório do veículo (DPVAT)',
};
