import BREAKPOINTS from '@lib/constants/breakpoints';
import PrimaryButton from 'components/atoms/buttons/PrimaryButton';
import Section from 'components/atoms/Section';
import { Heading2, LightParagraph } from 'components/atoms/Typography';
import COLORS from 'lib/constants/colors';
import ROUTES from 'lib/constants/routes';
import { trackGetQuoteClick } from 'lib/services/analytics';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Section)`
  h2 {
    margin-bottom: 24px;
    max-width: 700px;
  }

  a {
    color: ${COLORS.pink};

    :hover {
      text-decoration: underline;
    }
  }

  button {
    min-width: 320px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    a,
    button {
      width: 100%;
      min-width: unset;
    }
  }
`;

interface Props {
  description?: React.ReactText | React.ReactElement;
  buttonText: string;
  title: React.ReactText | React.ReactElement;
  href?: string;
  pastel?: boolean;
}

const JoinSection: FC<Props> = ({
  description,
  buttonText,
  title,
  children,
  href = ROUTES.startForm(),
  pastel = true,
}) => (
  <Wrapper pastel={pastel}>
    <Heading2 center>{title}</Heading2>
    {description && (
      <LightParagraph
        style={{ textAlign: 'center', maxWidth: 800, marginBottom: 32 }}
      >
        {description}
      </LightParagraph>
    )}
    <Link href={href}>
      <a>
        <PrimaryButton
          pink={pastel}
          onClick={() => trackGetQuoteClick('join section')}
        >
          {buttonText}
        </PrimaryButton>
      </a>
    </Link>
    {children}
  </Wrapper>
);

export default JoinSection;
